export default {
  namespaced: true,
  state: {
    is_call_api: false,
    test_id: null,
    title_test: "",
    sub_title: "",
    description_test: "",
    time_restriction_test: "No",
    duration_test: null,
    cover_test_data: "",
    cover_test_file: null,
    is_upload_cover_test: 0,
    data_option_skills: [],
    grading_scale: null,
    dialogPreview: false,
    type_expand: 0,
    paid_test: false,
    tags: [],
    tag_selected: [],
    pdf_test_data: null,
    pdf_test_file: null,
    is_upload_pdf_test: 0,
    predict: 0,
    expired_predict_date: null,
    product_category_id: null,
    list_course_by_category: [],
    topic_id: null,
    list_situation_code: [],
    situation_code: null,
    list_sub_situation_code: [],
  },
  getters: {
    cover_test_data: (state) => {
      return state.cover_test_data;
    },
    cover_test_file: (state) => {
      return state.cover_test_file;
    },
    is_upload_cover_test: (state) => {
      return state.is_upload_cover_test;
    },
    data_option_skills: (state) => {
      return state.data_option_skills;
    },
    pdf_test_data: (state) => {
      return state.pdf_test_data;
    },
    pdf_test_file: (state) => {
      return state.pdf_test_file;
    },
    is_upload_pdf_test: (state) => {
      return state.is_upload_pdf_test;
    },
  },
  actions: {},
  mutations: {
    setIsCallApi(state, payload) {
      state.is_call_api = payload;
    },
    setTestId(state, payload) {
      state.test_id = payload;
    },
    setTitleTest(state, payload) {
      state.title_test = payload;
    },
    setSubTitle(state, payload) {
      state.sub_title = payload;
    },
    setDescriptionTest(state, payload) {
      state.description_test = payload;
    },
    setTimeRestrictionTest(state, payload) {
      state.time_restriction_test = payload;
    },
    setDurationTest(state, payload) {
      state.duration_test = payload;
    },
    setCoverTest(state, payload) {
      state.cover_test_data = payload;
    },
    setCoverFileTest(state, payload) {
      state.cover_test_file = payload;
    },
    setIsUploadCoverTest(state, payload) {
      state.is_upload_cover_test = payload;
    },
    setOptionSkill(state, payload) {
      state.data_option_skills = payload;
    },
    deleteItemOptionSkill(state, payload) {
      state.data_option_skills.splice(
        state.data_option_skills.findIndex((e) => e === payload),
        1
      );
    },
    setGradingScale(state, payload) {
      state.grading_scale = payload;
    },
    setDialogPreview(state, payload) {
      state.dialogPreview = payload;
    },
    setTypeExpand(state, payload) {
      state.type_expand = payload;
    },
    setPaidTest(state, payload) {
      state.paid_test = payload;
    },
    setTags(state, payload) {
      state.tags = payload;
    },
    setTagSelected(state, payload) {
      state.tag_selected = payload;
    },
    setPdfTest(state, payload) {
      state.pdf_test_data = payload;
    },
    setPdfFileTest(state, payload) {
      state.pdf_test_file = payload;
    },
    setIsUploadPdfTest(state, payload) {
      state.is_upload_pdf_test = payload;
    },
    setPredict(state, payload) {
      state.predict = payload;
    },
    setExpiredPredictDate(state, payload) {
      state.expired_predict_date = payload;
    },
    setProductCategoryId(state, payload) {
      state.product_category_id = payload;
    },
    setListCourseByCategory(state, payload) {
      state.list_course_by_category = payload;
    },
    setTopicId(state, payload) {
      state.topic_id = payload;
    },
    setListSituationCode(state, payload) {
      state.list_situation_code = payload;
    },
    setSituationCode(state, payload) {
      state.situation_code = payload;
    },
    setListSubSituationCode(state, payload) {
      state.list_sub_situation_code = payload;
    },
  },
};
