const ID_TOKEN_KEY = "id_token";
const DATA_PERMISSION = "data_permission";
const DATA_USER = "data_user";
const DATA_SEACRH = "data_search";

// action token
export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const saveToken = (token) => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

// action permission
export const getDataPermission = () => {
  return window.localStorage.getItem(DATA_PERMISSION);
};

export const saveDataPermission = (token) => {
  window.localStorage.setItem(DATA_PERMISSION, token);
};

export const destroyDataPermission = () => {
  window.localStorage.removeItem(DATA_PERMISSION);
};

// action user
export const getUser = () => {
  return window.localStorage.getItem(DATA_USER);
};

export const saveUser = (user) => {
  window.localStorage.setItem(DATA_USER, JSON.stringify(user));
};

export const destroyUser = () => {
  window.localStorage.removeItem(DATA_USER);
};

//
export const getInputSearch = () => {
  return window.localStorage.getItem(DATA_SEACRH);
};
export const saveInputSearch = (search) => {
  window.localStorage.setItem(DATA_SEACRH, JSON.stringify(search));
};
export const destroyInputSearch = () => {
  window.localStorage.removeItem(DATA_SEACRH);
};

export default {
  getToken,
  saveToken,
  destroyToken,
  getDataPermission,
  saveDataPermission,
  destroyDataPermission,
  getUser,
  saveUser,
  destroyUser,
  saveInputSearch,
  destroyInputSearch,
  getInputSearch,
};
