import Vue from "vue";

export default {
  namespaced: true,
  state: {
    tabSkill: 0,
    tabSection: 0,
    skills: [],
    indexSkill: null,
    indexSection: null,
    indexExercise: null,
    indexQuestion: null,
    dialogSkill: false,
    type_form_skill: null,
    title_skill: null,
    duration_skill: 30,
    skill_id: null,
    dialogSection: false,
    dialogSortSection: false,
    type_form_section: null,
    title_section: null,
    description_section: null,
    section_id: null,
    dialogElement: false,
    type_form_element: null,
    element_form_display: false,
    type_element: null,
    element_id: null,
    element_audio_data: null,
    element_post_data: null,
    element_image_data: null,
    element_audio_file: null,
    element_image_file: null,
    element_pdf_data: null,
    element_pdf_file: null,
    element_description: null,
    element_has_slow_sound: false,
    videos: [],
    video_element_id: null,
    video_element_data: null,
    dialogChooseVideo: false,
    type_add_video: null,
    dialogExercise: false,
    dialogSortExercise: false,
    type_form_exercise: null,
    optionShortAnswer: [""],
    dialogShortAnswer: false,
    correctShortAnswer: null,
    refShortAnswer: {},
    exercise_id: null,
    dialogAddAudioTime: false,
    element_audio_section: null,
    dialogQuestion: false,
    dialogQuestionVWR: false,
    dialogQuestionIeltsVWRPart1: false,
    dialogQuestionUniversal: false,
    type_form_question: null,
    type_form_question_vwr: null,
    type_form_question_ielts_vwr_part1: null,
    type_form_question_universal: null,
    question_id: null,
    answer_id: null,
    album_element_id: null,
    album_element_data: [],
    model_sub_element: null,
    dialogSubElement: false,
    dataAddOptionSingleChoiceParagraph: [""],
    dialogAddOptionSingleChoiceParagraph: false,
    contentSingleChoiceInParagraph: "",
    dataCorrectSingleChoiceParagraph: [],
    refSingleChoiceInParagraph: {},
    dialogTimestampVideo: false,
    dialogSuggestOrderQuestion: false,
    element_youtube_data: null,
    dialogSortQuestion: false,
    listWeight: [],
    dialogSortSkill: false,
    data_question_matching_pair_input: [],
    matching_pair_question_type: null,
    data_option_matching_pair_input: [],
    matching_pair_option_type: null,
    data_correct_matching_pair: [],
    product_categories: [],
    dialogAddPhoneticToTitleQuestion: false,
    refTitleQuestion: {},
  },
  getters: {
    skills: (state) => {
      return state.skills;
    },
    dialogSkill: (state) => {
      return state.dialogSkill;
    },
    optionShortAnswer: (state) => {
      return state.optionShortAnswer;
    },
    dataAddOptionSingleChoiceParagraph: (state) => {
      return state.dataAddOptionSingleChoiceParagraph;
    },
  },
  actions: {},
  mutations: {
    addExercise(state, payload) {
      let dataCheck =
        state.skills[state.indexSkill].sections[state.indexSection].exercises;
      if (dataCheck === undefined || dataCheck === null) {
        state.skills[state.indexSkill].sections[state.indexSection].exercises =
          [];
      }
      let indexExer =
        state.skills[state.indexSkill].sections[state.indexSection].exercises
          .length;
      Vue.set(
        state.skills[state.indexSkill].sections[state.indexSection].exercises,
        indexExer,
        payload
      );
    },
    setSkills(state, payload) {
      state.skills = [...payload];
    },
    setTabSkill(state, payload) {
      state.tabSkill = payload;
    },
    setTabSection(state, payload) {
      state.tabSection = payload;
    },
    setDiaLogSkill(state, payload) {
      state.dialogSkill = payload;
    },
    setTypeFormSkill(state, payload) {
      state.type_form_skill = payload;
    },
    setTitleSkill(state, payload) {
      state.title_skill = payload;
    },
    setDurationSkill(state, payload) {
      state.duration_skill = payload;
    },
    addSkill(state, payload) {
      state.skills.push(payload);
    },
    updateSkill(state, payload) {
      state.skills[payload.index].duration = payload.duration;
      state.skills[payload.index].marking_point = payload.marking_point;
    },
    setSkillId(state, payload) {
      state.skill_id = payload;
    },
    setIndexSkill(state, payload) {
      state.indexSkill = payload;
    },
    setTypeFormSection(state, payload) {
      state.type_form_section = payload;
    },
    setDialogSection(state, payload) {
      state.dialogSection = payload;
    },
    setDialogSortSection(state, payload) {
      state.dialogSortSection = payload;
    },
    setIndexSection(state, payload) {
      state.indexSection = payload;
    },
    setSectionId(state, payload) {
      state.section_id = payload;
    },
    setDialogElement(state, payload) {
      state.dialogElement = payload;
    },
    setTypeFormElement(state, payload) {
      state.type_form_element = payload;
    },
    setElementFormDisplay(state, payload) {
      state.element_form_display = payload;
    },
    setTypeElement(state, payload) {
      state.type_element = payload;
    },
    setElementId(state, payload) {
      state.element_id = payload;
    },
    setElementPostData(state, payload) {
      state.element_post_data = payload;
    },
    setElementAudioData(state, payload) {
      state.element_audio_data = payload;
    },
    setElementImageData(state, payload) {
      state.element_image_data = payload;
    },
    setElementAudioFile(state, payload) {
      state.element_audio_file = payload;
    },
    setElementImageFile(state, payload) {
      state.element_image_file = payload;
    },
    setVideos(state, payload) {
      state.videos = payload;
    },
    setDialogChooseVideo(state, payload) {
      state.dialogChooseVideo = payload;
    },
    setTypeAddVideo(state, payload) {
      state.type_add_video = payload;
    },
    setVideoElementData(state, payload) {
      state.video_element_data = payload;
    },
    setVideoElementId(state, payload) {
      state.video_element_id = payload;
    },
    setDialogExercise(state, payload) {
      state.dialogExercise = payload;
    },
    setDialogSortExercise(state, payload) {
      state.dialogSortExercise = payload;
    },
    setTypeFormExercise(state, payload) {
      state.type_form_exercise = payload;
    },
    setIndexExercise(state, payload) {
      state.indexExercise = payload;
    },
    setOptionShortAnswer(state, payload) {
      state.optionShortAnswer = payload;
    },
    changeValueShortAnswer(state, payload) {
      state.optionShortAnswer.splice(payload.index, 1, payload.value);
    },
    deleteOptionShortAnswer(state, payload) {
      state.optionShortAnswer.splice(payload.index, 1);
    },
    addOptionShortAnswer(state, payload) {
      state.optionShortAnswer.push(payload);
    },
    setDialogShortAnswer(state, payload) {
      state.dialogShortAnswer = payload;
    },
    setCorrectShortAnswer(state, payload) {
      state.correctShortAnswer = payload;
    },
    setRefShortAnswer(state, payload) {
      state.refShortAnswer = payload;
    },
    setExerciseId(state, payload) {
      state.exercise_id = payload;
    },
    setDialogAddAudioTime(state, payload) {
      state.dialogAddAudioTime = payload;
    },
    setElementAudioSection(state, payload) {
      state.element_audio_section = payload;
    },
    setDialogQuestionVWR(state, payload) {
      state.dialogQuestionVWR = payload;
    },
    setDialogQuestion(state, payload) {
      state.dialogQuestion = payload;
    },
    setTypeFormQuestion(state, payload) {
      state.type_form_question = payload;
    },
    setTypeFormQuestionVWR(state, payload) {
      state.type_form_question_vwr = payload;
    },
    setQuestionId(state, payload) {
      state.question_id = payload;
    },
    setAnswerId(state, payload) {
      state.answer_id = payload;
    },
    setIndexQuestion(state, payload) {
      state.indexQuestion = payload;
    },
    setAlbumElementId(state, payload) {
      state.album_element_id = payload;
    },
    setAlbumElementData(state, payload) {
      state.album_element_data = [...payload];
    },
    setModelSubElement(state, payload) {
      state.model_sub_element = payload;
    },
    setDialogSubElement(state, payload) {
      state.dialogSubElement = payload;
    },
    setContentSingleChoiceInParagraph(state, payload) {
      state.contentSingleChoiceInParagraph = payload;
    },
    setDialogAddOptionSingleChoiceParagraph(state, payload) {
      state.dialogAddOptionSingleChoiceParagraph = payload;
    },
    setRefSingleChoiceInParagraph(state, payload) {
      state.refSingleChoiceInParagraph = payload;
    },
    setDataAddOptionSingleChoiceParagraph(state, payload) {
      state.dataAddOptionSingleChoiceParagraph = payload;
    },
    setDataCorrectSingleChoiceParagraph(state, payload) {
      state.dataCorrectSingleChoiceParagraph = payload;
    },
    setElementPdfData(state, payload) {
      state.element_pdf_data = payload;
    },
    setElementPdfFile(state, payload) {
      state.element_pdf_file = payload;
    },
    setDialogTimestampVideo(state, payload) {
      state.dialogTimestampVideo = payload;
    },
    setDialogSuggestOrderQuestion(state, payload) {
      state.dialogSuggestOrderQuestion = payload;
    },
    setElementYoutube(state, payload) {
      state.element_youtube_data = payload;
    },
    setDialogSortQuestion(state, payload) {
      state.dialogSortQuestion = payload;
    },
    setElementDescription(state, payload) {
      state.element_description = payload;
    },
    setListWeight(state, payload) {
      state.listWeight = payload;
    },
    setDialogSortSkill(state, payload) {
      state.dialogSortSkill = payload;
    },
    setDataQuestionMatchingPairInput(state, payload) {
      state.data_question_matching_pair_input = payload;
    },
    setMatchingPairQuestionType(state, payload) {
      state.matching_pair_question_type = payload;
    },
    setDataOptionMatchingPairInput(state, payload) {
      state.data_option_matching_pair_input = payload;
    },
    setMatchingPairOptionType(state, payload) {
      state.matching_pair_option_type = payload;
    },
    setDataCorrectMatchingPair(state, payload) {
      state.data_correct_matching_pair = payload;
    },
    setProductCategories(state, payload) {
      state.product_categories = payload;
    },
    setDialogAddPhoneticToTitleQuestion(state, payload) {
      state.dialogAddPhoneticToTitleQuestion = payload;
    },
    setRefTitleQuestion(state, payload) {
      state.refTitleQuestion = payload;
    },
    setDialogQuestionIeltsVWRPart1(state, payload) {
      state.dialogQuestionIeltsVWRPart1 = payload;
    },
    setTypeFormQuestionIeltsVwrPart1(state, payload) {
      state.type_form_question_ielts_vwr_part1 = payload;
    },
    setDialogQuestionUniversal(state, payload) {
      state.dialogQuestionUniversal = payload;
    },
    setTypeFormQuestionUniversal(state, payload) {
      state.type_form_question_universal = payload;
    },
    setElementHasSlowSound(state, payload) {
      state.element_has_slow_sound = payload;
    },
  },
};
