export default {
  namespaced: true,
  state: {
    characters: [],
    dataMockConversationQuestion: [
      {
        question_id: null,
        offset: 1,
        character_id: null,
        script: "",
        translate_script: "",
        audio_data: null,
        audio_file: null,
        audio_transcript: null,
      }
    ],
  },
  getters: {},
  actions: {},
  mutations: {
    setCharacter(state, payload) {
      state.characters = payload;
    },
    setDataMockConversationQuestion(state, payload) {
      state.dataMockConversationQuestion = payload;
    },


  },
}