export default {
  namespaced: true,
  state: {
    option_groups: [],
    questions: [],
    question: {},
    question_number: null,
  },
  getters: {},
  actions: {},
  mutations: {
    setOptionGroup(state, payload) {
      state.option_groups = payload;
    },
    pushItemToQuestions(state, payload) {
      state.questions.push(payload);
    },
    setQuestions(state, payload) {
      state.questions = payload;
    },
    setQuestion(state, payload) {
      state.question = payload;
    },
    setQuestionNumber(state, payload) {
      state.question_number = payload;
    },
  },
};
