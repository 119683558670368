export default {
  namespaced: true,
  state: {
    url_video_config: "",
    toggle_interactive_video: false,
    display_timestamp: "",
    display_explanation: "",
    continue_lesson_timestamp: "",
    waiting_time: "",
    time_limit: "",
    acceptable_score: "",
  },
  getters: {},
  actions: {},
  mutations: {
    setToggleInteractiveVideo(state, payload) {
      state.toggle_interactive_video = payload;
    },
    setDisplayTimestamp(state, payload) {
      state.display_timestamp = payload;
    },
    setDisplayExplanation(state, payload) {
      state.display_explanation = payload;
    },
    setContinueLessonTimestamp(state, payload) {
      state.continue_lesson_timestamp = payload;
    },
    setWaitingTime(state, payload) {
      state.waiting_time = payload;
    },
    setTimeLimit(state, payload) {
      state.time_limit = payload;
    },
    setAcceptableScore(state, payload) {
      state.acceptable_score = payload;
    },
    setUrlVideoConfig(state, payload) {
      state.url_video_config = payload;
    },
  },
}