import Ls from "./jwt.service.js";
//import toastr from "toastr";
import ApiService from "@/service/api.service.js";
import Toasted from "vue-toasted";
import Vue from "vue";
Vue.use(Toasted);

export default {
  async login(loginData) {
    try {
      let response = await ApiService.post("prep-app/login-pm", {
        username: loginData.email,
        password: loginData.password,
      });
      Ls.saveToken(response.data.access_token);
      Ls.saveDataPermission(response.data.permissions);
      Ls.saveUser(response.data.user);
      Vue.toasted.success("Logged In!", {
        theme: "toasted-primary",
        position: "top-right",
        duration: 2000,
      });
      return response.data.access_token;
    } catch (error) {
      if (error.response.status === 401) {
        Vue.toasted.error("Username or password is incorrect.", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        return;
      }
    }
  },

  async logout() {
    try {
      await ApiService.post("logout-fe");

      Ls.destroyToken();
      Ls.destroyDataPermission();
      Ls.destroyUser();
      Ls.destroyInputSearch();
      //toastr["success"]("Logged out!", "Success");
      return "200";
    } catch (error) {
      // console.log("Error", error.message);
    }
  },

  async check() {
    let response = Ls.getToken();
    // console.log(response);

    return !!response;
  },

  async canAccess(permission) {
    let permissions = Ls.getDataPermission();
    console.log(permissions, permission);
    return false;
  },
};
