export const SET_DATA_MARKING_TICKET_ANSWER = "setDataMarkingTickerAnswer";

export default {
  namespaced: true,
  state: {
    ticket_detail: {},
    dataMarkingTicketAnswer: [],
    data_marking_tag_speaking: {
      good_use: [],
      error: [],
    },
  },
  getters: {},
  actions: {},
  mutations: {
    resetDataMarkingTicketAnswer(state) {
      state.dataMarkingTicketAnswer = [];
    },
    SET_DATA_MARKING_TICKET_ANSWER(state, payload) {
      /*state.dataMarkingTicketAnswer[payload.index] = payload.answer;*/
      state.dataMarkingTicketAnswer.push(payload.answer);
    },
    setScoreAnswer(state, payload) {
      state.dataMarkingTicketAnswer[payload.index].score = payload.value;
    },
    setCommentAnswer(state, payload) {
      state.dataMarkingTicketAnswer[payload.index].comment = payload.value;
    },
    setScoreDetailAnswer(state, payload) {
      state.dataMarkingTicketAnswer[payload.indexAnswer].details[
        payload.indexDetail
      ].score = payload.value;
    },
    setCommentDetailAnswer(state, payload) {
      state.dataMarkingTicketAnswer[payload.indexAnswer].details[
        payload.indexDetail
      ].comment = payload.value;
    },
    setDataMarkingRecording(state, payload) {
      state.dataMarkingTicketAnswer[payload.indexAnswer].marking_data =
        payload.value;
    },
    setActionPlans(state, payload) {
      state.dataMarkingTicketAnswer[payload.index].action_plans =
        payload.action_plans;
    },
    setGeneralComment(state, payload) {
      state.dataMarkingTicketAnswer[payload.index].general_comment =
        payload.general_comment;
    },
    setdataMarkingTagSpeaking(state, payload) {
      state.data_marking_tag_speaking = payload.data;
    },
    setTicketDetail(state, payload) {
      state.ticket_detail = payload.data;
    },
    resetTicketDetail(state) {
      state.ticket_detail = {};
    },
    setScoreSubCriteriaAnswer(state, payload) {
      state.dataMarkingTicketAnswer[payload.indexAnswer].details[
        payload.indexDetail
      ].sub_criteria[payload.indexSubCriteria].score = payload.value;
    },
    setDescriptorSubCriteriaAnswer(state, payload) {
      state.dataMarkingTicketAnswer[payload.indexAnswer].details[
        payload.indexDetail
      ].sub_criteria[payload.indexSubCriteria].descriptor_id = payload.value;
    },
    setCommentSubCriteriaAnswer(state, payload) {
      state.dataMarkingTicketAnswer[payload.indexAnswer].details[
        payload.indexDetail
      ].sub_criteria[payload.indexSubCriteria].comment = payload.value;
    },
  },
};
