export default {
  namespaced: true,
  state: {
    is_call_api: false,
    dialogEntryTest: false,
    type_form_entry_test: null,
    test_result_analysis: [],
    test_result_analysis_id: null,
    dialogResultAnalysisAddFast: false,
    test_id: null,
    title_test: "",
    dialogAddTestFast: false,
    search_test_input: "",
    test_id_selected: null,
  },
  getters: {
    test_result_analysis: (state) => {
      return state.test_result_analysis;
    },
  },
  actions: {},
  mutations: {
    setIsCallApi(state, payload) {
      state.is_call_api = payload;
    },
    setDialogEntryTest(state, payload) {
      state.dialogEntryTest = payload;
    },
    setTypeFormEntryTest(state, payload) {
      state.type_form_entry_test = payload;
    },
    setTestResultAnalysis(state, payload) {
      state.test_result_analysis = [...payload];
    },
    setTestResultAnalysisId(state, payload) {
      state.test_result_analysis_id = payload;
    },
    setDialogResultAnalysisAddFast(state, payload) {
      state.dialogResultAnalysisAddFast = payload;
    },
    setTestId(state, payload) {
      state.test_id = payload;
    },
    setTitleTest(state, payload) {
      state.title_test = payload;
    },
    setDialogAddTestFast(state, payload) {
      state.dialogAddTestFast = payload;
    },
    setSearchTestInput(state, payload) {
      state.search_test_input = payload;
    },
    setTestIdSelected(state, payload) {
      state.test_id_selected = payload;
    },
  },
};
